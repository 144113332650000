<template>
    <div class="record">
        <h5header :name="$t('quotes.desc31')" :right="false" />
        <div class="record-con">

            <div class="record-list" v-if="info != undefined">
                <div class="list-info flex">
                    <div v-if="info.payCoinName != 'DU'">{{ $t('quotes.desc15') }}</div>
                    <div v-else>{{ $t('quotes.desc35') }}</div>
                    <p>{{ info.payCoinName }}/{{ info.returnCoinName }}</p>
                </div>

                <!-- <div class="list-info flex">
                    <div>{{ $t('quotes.desc16') }}</div>
                    <p v-if="info.sellType ==1">{{ $t('quotes.desc24') }}</p>
                    <p style="color:#C9FA5B" v-else>{{ $t('quotes.desc35') }}</p>
                </div> -->
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc17') }}</div>
                    <p>{{ common.cutXiaoNum1(info.payAmount) }} {{ info.amountCoinId }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc25') }}</div>
                    <p>{{ common.cutXiaoNum1(info.rate) }} {{ info.toDisPatchReturnName }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc19') }}</div>
                    <p>{{ common.cutXiaoNum1(info.fee) }} {{ info.feeCoinName }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc27') }}</div>
                    <p>{{ common.cutXiaoNum1(info.returnAmount) }} {{ info.toDisPatchReturnName }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc18') }}</div>
                    <p>{{ $t('market.desc77') }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc14') }}</div>
                    <p>{{ info.datetime }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('quotes.desc13') }}</div>
                    <p class="flex">{{ dealAddress(info.orderNo) }} <img src="../../public/images/new/copy.png" alt=""
                            @click="copy($event, info.orderNo)" /></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import Clipboard from '@/utils/libs/clipboard'
export default {
    components: {
        h5header
    },
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        this.info = JSON.parse(localStorage.getItem('recordinfo'));
    },
    methods: {
        selectTypeName(type) {
            switch (type) {
                case 1:
                    return this.$t("asset.desc100");
                case 2:
                    return this.$t("asset.desc101");
                case 3:
                    return this.$t("new.desc9");
                default:
                    break;
            }
        },
        copy(e, text) {
            // @click="copy($event,googleRandom.secretKey)"
            let that = this;
            Clipboard(e, text).then((res) => {
                that.$message.success(this.$t('planet.desc61'))
            });
        },
        dealAddress(str) {
            if (str && str.length > 18) {
                return str.substring(0, 6) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },
    }
}
</script>

<style lang="less" scoped>
.record {
    padding: 20px 16px 0;
    background: url('../../public/images/new/h5bg.png') center no-repeat;
    background-size: 100% 100%;

    .record-con {
        padding: 56px 0;

        .record-price {
            padding: 24px;
            border-radius: 8px;
            background: #181818;

            .price-left {
                flex: 1;
                font-size: 12px;
                color: rgba(255, 255, 255, .6);
                line-height: 12px;

                div {
                    margin-bottom: 8px;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }

            img {
                flex: 0 0 56px;
                width: 56px;
                height: 56px;
                margin-left: 12px;
            }
        }

        .record-list {
            padding: 0 16px;
            margin-top: 12px;
            border-radius: 8px;
            background: #181818;

            .list-info {
                padding: 16px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);

                &:last-child {
                    border-bottom: 0;
                }

                &:nth-child(2) {
                    p {
                        color: #EB4343;
                    }
                }

                div {
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: rgba(255, 255, 255, .6);
                }

                p {
                    flex: 0 0 80%;
                    text-align: right;
                    font-size: 13px;
                    color: #fff;
                    justify-content: flex-end;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-top: 3px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .record {
        padding: 0 16px;
        min-height: 100vh;
    }
}
</style>